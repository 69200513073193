var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};
var site = site || {};

site.client = site.client || {};
site.direction = site.direction || {};

(function ($) {
  $(document).on('product.skuDisplay', '.js-product', function (e, skuBaseId) {
    var $gallery = $('.js-product-gallery', $(this));

    if ($gallery.length < 1) {
      return null;
    }
    var prodId = $gallery.data('product-id');

    if (prodcat.data.isPaletteMultiSku(prodId) || prodcat.data.isPaletteMultiProduct(prodId)) {
      Drupal.behaviors.productGalleryV1.displaySkuImage($gallery, skuBaseId);
    } else {
      Drupal.behaviors.productGalleryV1.displaySmoosh($gallery, skuBaseId);
    }
  });

  $(document).on('product.skuSelect', '.js-product', function (e, skuBaseId, urlhit) {
    var $gallery = $('.js-product-gallery', $(this));

    if ($gallery.length < 1) {
      return null;
    }
    var prodId = $gallery.data('product-id');

    if (prodcat.data.isPaletteMultiSku(prodId) || prodcat.data.isPaletteMultiProduct(prodId)) {
      Drupal.behaviors.productGalleryV1.displaySkuImage($gallery, skuBaseId);
    } else {
      Drupal.behaviors.productGalleryV1.displaySmoosh($gallery, skuBaseId, urlhit);
    }
  });

  $(document).on('product.initThumbnailCarousel', function (e, $container) {
    Drupal.behaviors.productGalleryV1.initThumbnailCarousel($container);
  });

  $(document).on('product.initAltImages', function (e, altGalleryGroups) {
    Drupal.behaviors.productGalleryV1.initAltImages(altGalleryGroups);
  });

  $(document).on('click', '.js-product-gallery__div-thumb--product, .js-product-gallery__div-thumb--smoosh', function (e) {
    e.preventDefault();
    var $thumbLink = $(this);
    var idx = $thumbLink.data('gallery-index');
    var $productEl = prodcat.ui.getProductEl($thumbLink);

    Drupal.behaviors.productGalleryV1.selectImage($('.js-product-gallery', $productEl), idx);
    if ($thumbLink.hasClass('active')) {
      $thumbLink.attr('aria-selected', 'true');
    } else {
      $thumbLink.attr('aria-selected', 'false');
    }
  });
  /* ADA MPP Quickview,
   * Thumb image keydown enent triggered by click event
   */
  $(document).on('keydown', '.js-product-gallery__div-thumb--product, .js-product-gallery__div-thumb--smoosh', function (e) {
    if (site.getKeycode(e) === 13) {
      $(this).trigger('click');
    }
  });

  $(document).on('click', '.js-product-gallery__img-thumb--product', function (e) {
    e.preventDefault();
    var $thumbLink = $(this);
  });

  Drupal.behaviors.productGalleryV1 = {
    hideSmoosh: false,
    skuAltImage: null,
    galleryClone: $('.js-product-gallery').clone(true, true),
    galleryClonefn: function () {
      if (this.galleryClone.length === 0 || page_data['catalog-mpp']) {
        this.galleryClone = $('.js-product-gallery').clone(true, true);
      }

      return this.galleryClone;
    },
    attach: function (context, settings) {
      if (settings && settings.bb_product) {
        this.hideSmoosh = settings.bb_product.spp_hide_smoosh;
      }

      this.init($('.js-product-gallery', context));
    },
    _initZoom: function () {
      var $largeImg = $('.js-gallery-img-large.active');

      if ($largeImg.parents('.js-quickshop').length > 0) {
        return null;
      }
      $largeImg.elevateZoom({
        zoomWindowPosition: 1,
        zoomWindowOffetx: 10,
        zoomWindowWidth: 550,
        zoomWindowHeight: 450,
        borderSize: 0
      });
    },
    _removeZoom: function () {
      var $largeImg = $('.js-gallery-img-large.active');

      $.removeData($largeImg, 'elevateZoom');// remove zoom instance from image
      $('.zoomContainer').remove();// remove zoom container from DOM
    },
    init: function ($gallery, context) {
      if (!$gallery || $gallery.length < 1) {
        return null;
      }
      var $contextProductEl = prodcat.ui.getContextProductEl($gallery);
      var $largeImages = $('.js-product-gallery__main img', $gallery);
      var $thumbDivs = $('.js-product-gallery__div-thumb--smoosh, js-product-gallery__div-thumb--product', $gallery);
      var altGalleryGroups = [$('.product-gallery__photo--large-alt', $gallery), $('.product-gallery__thumb-alt', $gallery)];

      this.initAltImages(altGalleryGroups);

      // build carousel for >4 images
      var carouselBreakPoint = 4;

      if ($('.js-product-gallery__thumb', $gallery).length > carouselBreakPoint) {
        $('.js-product-gallery__thumbs', $gallery).slick({
          rtl: site.direction.isRTL,
          infinite: false,
          slidesToShow: carouselBreakPoint,
          slidesToScroll: 1,
          centerMode: true
        });
      }
      if (!Modernizr.touch && !$contextProductEl.data('disable-zoom')) {
        this._initZoom();
      }
      this.initThumbnailCarousel(context);
    }, // end init
    displaySmoosh: function ($gallery, skuBaseId, urlhit) {
      if (this.hideSmoosh) {
        return null;
      }
      var skuData = prodcat.data.getSku(skuBaseId);

      if (!_.isNull(skuData)) {
        if (_.isArray(skuData.IMAGE_SMOOSH_L) && skuData.IMAGE_SMOOSH_L.length) {
          $('.js-product-gallery__photo--smoosh', $gallery).attr({
            'src': skuData.IMAGE_SMOOSH_L[0],
            'alt': skuData.SHADENAME
          }).data('zoom-image', skuData.IMAGE_SMOOSH_XL[0]);
          if (!_.isNull(skuData.NUMBER_OF_ALT_IMAGES)) {
            this.skuAltImage = skuData.NUMBER_OF_ALT_IMAGES;
          }
          this.selectImage($gallery, 1, urlhit, skuData.NUMBER_OF_ALT_IMAGES);
          $('.js-product-gallery__img-thumb--smoosh', $gallery).attr({
            'src': skuData.IMAGE_SMOOSH_S[0],
            'alt': skuData.SHADENAME
          });
        }
      }
    },
    displaySkuImage: function ($gallery, skuBaseId) {
      var skuData = prodcat.data.getSku(skuBaseId);

      if (!_.isNull(skuData)) {
        if (_.isArray(skuData.IMAGE_SKU_L) && skuData.IMAGE_SKU_L.length) {
          $('.js-product-gallery__photo--product', $gallery).attr('src', skuData.IMAGE_SKU_L[0]).data('zoom-image', skuData.IMAGE_SKU_XL[0]);
          var $thumbimg = $('.js-product-gallery__img-thumb--product', $gallery);

          $thumbimg.attr('src', skuData.IMAGE_SKU_S[0]);
        }
      }
    },
    /*
     * This function will reprocess the product image list based on
     * sku/product.
     */
    displaySkuAltImage: function ($gallery, skuBaseId, urlhit) {
      var $prodMainImageContainer = $('.js-product-gallery__main', $gallery);
      var $pcProdThumbContainer = $('.js-product-gallery__thumbs', $gallery);
      var $mobileThumbContainer = $('.js-mobile-product-info .js-product-gallery__main');
      var skuData = prodcat.data.getSku(skuBaseId);
      var prodData = prodcat.data.getProduct(skuData.PRODUCT_ID);
      var $contextProductEl = prodcat.ui.getContextProductEl($gallery);
      var skuAltImageCount = skuData.NUMBER_OF_ALT_IMAGES;
      var productAltImages = prodData.NUMBER_OF_ALT_IMAGES;
      var allProdImages = [];
      var productImages = [];
      var skuImages = [];
      var thumbBaseImage = '';
      var className = '';
      var thumbAltClass = '';
      var isSkuBaseImage = false;
      var skuImgSizeArray = ['S', 'L', 'XL']; // Sku level images
      var prodImgSizeArray = ['S', 'L']; // Prod level images. Since Array keys are different sku level and prod level
      var index_position = '';
      var _get_default = function (value, _default) {
        return !_.isUndefined(value) ? value : _default;
      };
      var mobile_sku_index_position = _get_default($contextProductEl.data('mobile-sku-index-position'), 0);
      var desktop_sku_index_position = _get_default($contextProductEl.data('desktop-sku-index-position'), 1);
      var sku_alt_image_limit = _get_default($contextProductEl.data('sku-alt-image-limit'), null);
      var product_alt_image_limit = _get_default($contextProductEl.data('product-alt-image-limit'), null);
      var total_alt_image_limit = _get_default($contextProductEl.data('total-alt-image-limit'), null);
      var _check_limit = function (limit, count) {
        if (_.isNull(limit)) {
          return false;
        }

        return count >= limit;
      };
      var i = 1;
      var j = 1;

      // Let other js know we are about to replace the current image list
      $gallery.trigger('gallery.startMungeImages');

      var total_alts_processed = 0;

      // Sku level base image
      if (skuData.IMAGE_ALT_S) {
        $.each(skuImgSizeArray, function (key, sizeName) {
          skuImages[sizeName] = skuData['IMAGE_ALT_' + sizeName][0];
        });
        isSkuBaseImage = true;
        thumbBaseImage = skuData.IMAGE_ALT_S[0];
        allProdImages.push(skuImages);

        // Sku level Alt images
        for (i = 1; i <= skuAltImageCount; i++) {
          if (_check_limit(sku_alt_image_limit, i - 1)) {
            break;
          }
          if (_check_limit(total_alt_image_limit, total_alts_processed)) {
            break;
          }
          skuImages = [];
          $.each(skuImgSizeArray, function (key, sizeName) {
            skuImages[sizeName] = skuData['IMAGE_ALT_' + sizeName][i];
          });
          allProdImages.push(skuImages);
          total_alts_processed++;
        }
      } else {
      // Prod level Base image
        $.each(prodImgSizeArray, function (key, sizeName) {
          productImages[sizeName] = prodData['IMAGE_' + sizeName][0];
        });
        productImages['XL'] = prodData['IMAGE_XXL'][0];
        allProdImages.push(productImages);
        thumbBaseImage = prodData.IMAGE_S[0];
      }
      // Prod level Alt images
      for (j = 1; j <= productAltImages; j++) {
        if (_check_limit(product_alt_image_limit, j - 1)) {
          break;
        }
        if (_check_limit(total_alt_image_limit, total_alts_processed)) {
          break;
        }
        productImages = [];
        $.each(prodImgSizeArray, function (key, sizeName) {
          productImages[sizeName] = prodData['IMAGE_' + sizeName][j];
        });
        productImages['XL'] = prodData['IMAGE_XXL'][j];
        allProdImages.push(productImages);
        total_alts_processed++;
      }

      if (prodData.shaded) {
        productImages = [];
        $.each(skuImgSizeArray, function (key, sizeName) {
          productImages[sizeName] = skuData['IMAGE_SMOOSH_' + sizeName][0];
        });
      }
      allProdImages.splice(1, 0, productImages);

      $prodMainImageContainer.add($pcProdThumbContainer).add($mobileThumbContainer).empty();
      $prodMainImageContainer.add($pcProdThumbContainer).add($mobileThumbContainer).removeClass('slick-initialized');

      if (isSkuBaseImage) {
        index_position = urlhit || site.client.isMobile ? mobile_sku_index_position : desktop_sku_index_position;
      } else if (urlhit || !isSkuBaseImage) {
        index_position = 1;
      }

      $(allProdImages).each(function (index, images) {
        if (images['S'].indexOf('bb_smoosh') > 1) {
          className = 'smoosh';
        } else if (index > 1) {
          className = 'product product-gallery__photo--large-alt';
          thumbAltClass = ' product-gallery__thumb-alt';
        } else {
          className = 'product';
        }
        var $thumbImage = $('<img>', {
          class: 'product-gallery__photo product-gallery__photo--thumb js-product-gallery__img-thumb--' + className,
          src: images.S
        });
        var $thumImageContainer = $('<div/>', {
          class: 'product-gallery__thumb js-product-gallery__div-thumb--' + className + thumbAltClass,
          html: $thumbImage
        }).attr('data-gallery-index', index);
        var $largeImage = $('<img>', {
          class: 'js-gallery-img-large js-product-gallery__photo--' + className + ' product-gallery__photo--large fluid-image ',
          src: images.L
        }).attr('data-zoom-image', images.XL).attr('alt', prodData.PROD_RGN_NAME).attr('data-gallery-index', index);

        if (index === index_position) {
          $thumImageContainer.addClass('active');
          $largeImage.addClass('active');
        }

        $pcProdThumbContainer.append($thumImageContainer);
        $prodMainImageContainer.append($largeImage);
      });

      if ($gallery.parents('.full').length > 0) {
        $('.js-addtobag-image').attr('src', thumbBaseImage);
      }

      if (site.client.isMobile) {
        $mobileThumbContainer.html($prodMainImageContainer.html());
        $mobileThumbContainer.trigger('mobile::prodSmooshCarouselAdd');
      } else {
        this.initThumbnailCarousel();
      }

      // tell other JS that we've replaced the image list and also alert
      // to the intial active image
      $gallery.trigger('gallery.mungedImages', [index_position]);
    },

    selectImage: function ($gallery, idx, urlhit, skuAltImage) {
      skuAltImage = this.skuAltImage;
      var $largeImages = $('.js-product-gallery__main img, .js-product-gallery__main video', $gallery);
      var $thumbDivs = $('.js-product-gallery__div-thumb--smoosh, js-product-gallery__div-thumb--product', $gallery);
      var $productEl = prodcat.ui.getProductEl($gallery);
      var $contextProductEl = prodcat.ui.getContextProductEl($gallery);
      // currently the pallette slick js is too liberal with what carousels
      // it targets. added an opt-out for cases where we dont want that any of
      // that behavior
      var skip_mobile_slick_select = $contextProductEl.data('skip-mobile-slick-select');

      if (!Modernizr.touch) {
        this._removeZoom();
      }
      var hasVideos = $('.js-product-gallery__main video').length;

      if (hasVideos) {
        $('.js-product-gallery__main video')[0].pause();
        $('.js-product-gallery__main video')[0].currentTime = 0;
      }
      if (!urlhit || skuAltImage === null) {
        $largeImages.removeClass('active');
        $thumbDivs.removeClass('active');
        $('[data-gallery-index=' + idx + ']', $gallery).addClass('active');
      }
      if (hasVideos && $('.js-product-gallery__main video').hasClass('active')) {
        $('.js-product-gallery__main video')[0].controls = 0;
        $('.js-product-gallery__main video')[0].play();
      }
      if (!Modernizr.touch && !$contextProductEl.data('disable-zoom')) {
        this._initZoom();
      }
      if ($('.slick-slider.js-product-gallery__main').length && !skip_mobile_slick_select) {
        //  Show Selected Smoosh on Mobile Non/Palette ProdImage/Smoosh Carousel.
        //  todo: this code needs to be smarter about what carousels it targets
        $('.product__details .js-product-gallery__main').slickGoTo(1);
        var hasShade = page_data['catalog-spp'] !== null && page_data['catalog-spp']['products'][0]['shaded'] !== null ? true : false;

        if (site.client.isMobile && Drupal.settings.bb_product.display_sku_alt_image && !_.isNull(skuAltImage) && hasShade) {
          $('.js-product-gallery__main').first().slickGoTo(0);
        } else if (skuAltImage === null) {
          $('.js-product-gallery__main').first().slickGoTo(1);
        }
      }
      $gallery.trigger('gallery.selectImage', [idx]);
    },
    initAltImages: function (imageGroup) {
      for (groupId in imageGroup) {
        altImageCount = 2; // starting count for alt images
        imageGroup[groupId].each(function () {
          $(this).attr('data-gallery-index', altImageCount);
          altImageCount++;
        });
      }
    },
    initThumbnailCarousel: function (context) {
      if (context) {
        this.galleryClonefn();
      }
      var $thumbnail_array = $('.product-gallery__thumb', context);
      var $thumbnail_gallery = $('.product-gallery__thumbs', context);

      if ($thumbnail_array.length > 4) {
        // build row elements
        for (var i = 0; i < $thumbnail_array.length; i = i + 4) {
          $thumbnail_array.slice(i, i + 4).wrapAll('<div class="product-gallery__thumbs-row">');
        }

        $thumbnail_gallery.slick({
          rtl: site.direction.isRTL,
          infinite: false
        });
        $thumbnail_gallery.slickGoTo(0); // hack to fix bug with slick slider.
      }
    }

  }; // end Drupal.behaviors.productGalleryV1

  /**
   * Swatch - Mobile Prod Smoosh - v1
   */
  Drupal.behaviors.mobileprodImgSmooshCarouselV1 = {
    attach: function (context) {
      $(window).on('mobile::prodSmooshCarouselAdd', function (e) {
        // Mobile : Non Palette Product/Smoosh.
        if ($('.spp-mobile-page').length) {
          // vars for slick
          var prodCarousel = $('.js-product-gallery__main', 'body'),
            prodSmooshSlide = '.fluid-image';

          // render the carousel.
          prodCarousel.each(function () {
            var $this = $(this),
              startIndex = $this.children(':visible').index() || 0;

            $this.slick({
              rtl: site.direction.isRTL,
              mobileFirst: true,
              arrows: true,
              speed: 300,
              slide: prodSmooshSlide,
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: startIndex
            });
          });
        }
      });

      $(window).on('mobile::prodSmooshCarouselRemove', function (e) {
        $('.js-product-gallery__main', 'body').unslick('.js-product-gallery__main');
      });

      $(window).trigger('mobile::prodSmooshCarouselAdd');
    }
  }; // end Drupal.behaviors.mobileprodImgSmooshCarouselV1
})(jQuery);
